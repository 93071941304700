.buttonShowUsers {
    align-items: centers;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;
    margin-top: 20px;

    .buttonShowUsers:hover {
        cursor: pointer;
    }
}
.userlist {
    width: 700px;
    height: auto;
    border: 1px solid black;
    margin: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    
    h3 {
        margin: 5px;
    }
}