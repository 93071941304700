.double-dropdown {
    margin-left: 20px;
    display: flex;
}

.double-dropdown-button {
    margin-left: 50px;
    width: 370px;
}

.double-dropdown-info {
    margin: 20px;
    width: 370px;
    justify-content: center;
    text-align: left;
    border: 1px solid black;
}

.choixtype {
    margin-right: 10px;
    font-size: large;
}

.choixproduit {
    margin-right: 10px;
    font-size: large;
}

.type-info {
    margin: 10px;
}

.produit-info {
    margin: 10px;
}