.Titre {
    display: block;
    text-align: center;
    margin: 10px;
}

.Infos {
    display: block;
    text-align: center;
    margin: 25px;
    border: 1px solid black;
    padding-left: 10px;
}

.Table {
    display: flex;
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 40px;
}

.Table_Datecode {
    display: flexbox;
    margin-left: 235px;
}

.control {
    display: flex;
    margin-left: 40px;
    margin-top: 25px;
}

.control_button {
    position: relative;
    display: block;
    text-align: center;
    margin: 5px;
    width: 100px;
    border-radius: 5px;
    background-color: grey;
    border: solid 1px transparent;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    cursor: pointer;
    transition: all .3s ease-in-out;
    &:hover {
        background-color: $color-bl;
        border-color: rgb(255, 255, 255);
        transition: all .2s ease-in-out;
    }
}