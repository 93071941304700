.LoginNavbarItems {
    background: grey;
    color: white;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    max-width: 100%;
    height: 55px;
}

.Title {
    margin-left: 25px;
    margin-right: 25px;
    position: relative;
    color: white;
    &:hover{
        color:wheat;
    }
}

a {
    margin: 5px;
    padding: 10px;
    position: relative;
    color: white;
    &:hover{
        color:wheat;
    }
}

.login-link{
    margin-left: 70%;
}

.login-nav-active {
    &::after {
        content: '';
        width: 85%;
        height: 3.5px;
        background: $color-2;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
    }
}