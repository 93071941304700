.create_fsc_button{
    position: relative;
    display: block;
    text-align: center;
    margin: 5px;
    width: 150px;
    border-radius: 5px;
    background-color: grey;
    border: solid 1px transparent;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    cursor: pointer;
    transition: all .3s ease-in-out;
    &:hover {
        background-color: $color-bl;
        border-color: rgb(255, 255, 255);
        transition: all .2s ease-in-out;
    }
}