/** FADE-IN EFFECT  **/
@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}
/********************/

.login-form{
    text-align: center;
    animation: fadeEffect 3s; 
}

.login-button{
    display: flex;
    justify-content: center;
    align-items: center;
}

input{
    text-align:center;
    font-size: large;
    width: 300px;
    height: 36px;
    margin: 10px;
    border-radius: 8px;
}