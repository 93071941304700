.NavbarItems {
    background: grey;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    max-width: 100%;
    height: 55px;

    .Title {
        margin-left: 2%;
        margin-right: 1%;
        position: relative;
        color: white;
        &:hover{
            color:wheat;
        }
    }

    a {
        margin: 0.2%;
        padding: 0.5%;
        position: relative;
        color: white;
        &:hover{
            color:wheat;
        }
    }
    
    .navbar-logo {
        justify-self: start;
        margin-left: 20px;
        height: 50px;
        width: auto;        
        cursor: pointer;
    }

    .nav-active {
        &::after {
            content: '';
            width: 85%;
            height: 3.5px;
            background: $color-2;
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .connected{
        margin-left: auto;
        margin-right: 10px;
    }
    .disconnect{
        margin-right: 10px;
    }
}