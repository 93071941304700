.loading-text {
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    width: 200px;
}

.loading-icon {
    position: fixed;
    top: 30%;
    left: 47.5%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    width: 200px;
}