.content {
    display: block;
}

.infos {
    display: block;
    text-align: center;
    justify-content: center;
    border: 1px solid black;
    width: auto;
}

.buttons {
    display: f;
}
.colonne1-buttons {
    display: table-cell;
    margin: auto;
}

.colonne2-buttons {
    display: table-cell;
    margin: auto;
}

.colonne3-buttons {
    display: table-cell;
    margin: auto;
}

.colonne4-buttons {
    display: table-cell;
    margin: auto;
}