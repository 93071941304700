@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

@font-face{
  src: url("../public/fonts/TitilliumWeb-Regular.ttf");
  font-family: BL;  
} 

$font-1: "Poppins", sans-serif;
 
$color-1: #61dafb;
$color-2: #ffffff;
$color-3: #282c34;
$color-4: rgb(32, 35, 42);
$color-bl: rgb(227, 6, 19);

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 237mm;
  outline: 2cm #FFEAEA solid;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
  }
}

body {
  display: block;
  max-width: 100%;
  font-family: $font-1;
  color: $color-3;
  background-color: $color-2;
  margin: 0px;
}

li {
  list-style-type: none;
}

a:-webkit-any-link {
  text-decoration: none;
}

.button {
  position: relative;
  display: block;
  text-align: center;
  margin: 30px;
  width: 250px;
  height: 35px;
  border-radius: 15px;
  background-color: grey;
  border: solid 1px transparent;
  color: #fff;
  font-size: 17px;
  font-weight: 300;
  cursor: pointer;
  transition: all .3s ease-in-out;
  &:hover {
    background-color: $color-bl;
    border-color: rgb(255, 255, 255);
    transition: all .2s ease-in-out;
  } 
}