.contact {
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    border: 1px solid black;
    width: 800px;
}

.contact-message {
    margin: 10px;
}