.sending-data {
    z-index: 1;
    display: block;
    width: 500px;
    height: 150px;
    background-color: white;
    text-align: center;
    border: 1px solid black;
    position: fixed;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
}

.sendingdata-icon {
    position: fixed;
    top: 35%;
    left: 44%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    width: 200px;
}